import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import { CONTENT_MAX_WIDTH } from 'ui/shared/layout/utils';

import FooterLinks from './FooterLinks';
import { FooterSocialMediaLinks } from './FooterSocialMediaLinks';
import { getCopyrightText } from './utils/getCopyrightText';

const Footer = () => {
  const containerProps = {
    as: 'footer' as 'div',
    borderTopWidth: '1px',
    borderTopColor: 'solid',
  };

  return (
    <Box
      {...containerProps}
      border="none"
      position="relative"
      mt={8}
      _before={{
        content: '""',
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100vw',
        height: '5px',
        backgroundImage: `url('/static/footer_separator.svg')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'repeat',
      }}
    >
       <Box
        m="0 auto"
        alignItems="stretch"
        px="12"
        maxW={`${CONTENT_MAX_WIDTH}px`}
      >
        <FooterLinks />
        <FooterSocialMediaLinks />
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          w="full"
          textTransform="uppercase"
        >
          {/* Logo */}
          <Link href="/" mt={8} mb={8}>
            <Image
              alt="BlockBlockchain Scan | GUNZ"
              width="120"
              height="29"
              title="Blockchain Scan | GUNZ"
              src="/static/logo-company-name-gr.svg"
            />
          </Link>

          {/* Copy */}
          <Text opacity={0.4} cursor="default" textAlign="center" my={4} sx={{ wordSpacing: '6px' }}
          >
            {getCopyrightText()}
          </Text>

          {/* Version */}
          <Flex justify="center" align="center" opacity={0.4} >
            <Link
              href="https://gunbygunz.com/download/wallet_tou.pdf"
              target="_blank"
              _hover={{ transform: 'scale(1.05)', transition: 'transform 0.3s ease' }}
              mr={4}
            >
              Terms of service
            </Link>
            <Link
              href="https://gunbygunz.com/download/wallet_pp.pdf"
              target="_blank"
              _hover={{ transform: 'scale(1.05)', transition: 'transform 0.3s ease' }}
              mr={4}
            >
              Privacy policy
            </Link>
            <Text>Frontend: {config.UI.footer.frontendVersion}</Text>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(Footer);
